<template>
  <div class="filter-group">
    <div class="flex align-items-center justify-content-start options-wrapper full-width">
      <div class="title-dropdown" v-for="type in product.extendedDetails" :key="type.id">
        <OptionsTextInput v-if="type.type === 'Text'" :label="type.label" :value="isEditing ? selected[type.id] : formData[type.id]"
                          @updateField="updateSMH($event, type.id)" :placeholder="type.placeholder"/>
        <OptionsNumber
            v-if="type.type === 'Value' || type.type === 'Number'"
            :name="type.label"
            :value="isEditing ? selected[type.id] : formData[type.id]"
            :dropdownValue="formData.currency"
            :dropdownOptions="{options: type.options,  optionLabel: 'currencyCode', optionValue: 'currencyCode'}"
            :mode="type.type === 'Value' ? 'currency' : null"
            @updateField="updateSMH($event, type.id)"
        />

        <OptionsSelect
                v-if="type.type === 'Select'"
                :name="type.label"
                :dropdownValue="isEditing ? selected[type.id] : formData[type.id]"
                :dropdownOptions="{options: type.options.split(',') }"
                @updateDropdown="updateDropdown($event, type.id)"
        />

        <OptionsDate
            v-if="type.type === 'Date'"
            :value="isEditing ? selected[type.id] : formData[type.id]"
            :label="type.label"
            @updateDate="updateDate($event, `${type.id}`)"
            @setDate="setDate($event,`${type.id}`)"
            @setToToday="setToToday($event,`${type.id}`)"
        />
      </div>
      <div class="full-width" v-for="type in product.extendedDetails" :key="type.id">
        <OptionsNotes
            v-if="type.type === 'Note'"
            :label="type.label"
            :value="(isEditing ? selected[type.id] : formData[type.id])"
            :placeholder="type.placeholder"
            @updateNotes="updateSMH($event, 'notes')"
        />
      </div>

    </div>


  </div>
</template>

<script>
import {reactive, ref, computed, onMounted} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";
import OptionsTextInput from "@/components/selfManaged/advancedOptions/OptionsTextInput";
import OptionsNumber from "@/components/selfManaged/advancedOptions/OptionsNumber";
import OptionsSelect from "@/components/selfManaged/advancedOptions/OptionsSelect";
import OptionsDate from "@/components/selfManaged/advancedOptions/OptionsDate";
import OptionsNotes from "@/components/selfManaged/advancedOptions/OptionsNotes";


export default {
  name: "AdvancedOptionsGroup",
  components: {
    OptionsNotes,
    OptionsDate,
    OptionsTextInput,
    OptionsNumber,
    OptionsSelect
  },
  props: ['product', 'isEditing'],
  setup(props) {
    const store = useStore()
    const today = dayjs().format('DD/MM/YYYY');
    const useReminder = ref(false)

    onMounted(() => {
      let curr = store.state.selections.currencyOptions
      if (!curr) {
        store.dispatch('getCurrencyOptions')
      }
      let data = store.getters.getSelfManagedDetails
      if (data) {
        Object.keys(data).forEach(field => {
          if (props.isEditing) {
            if (!['planName','ownership'].includes(field)) {
              formData[field] = data[field]
            }
          } else {
            formData[field] = data[field]
          }
        })
      }
    })

    // set dynamically
    const formData = reactive({})

    const updateDate = (data, field) => {
      // check if valid date
      if (dayjs(data).isValid()) {
        formData[field] = dayjs(data).toDate()
        store.dispatch('setSMHField', {field: field, value: dayjs(data).toDate()})
      }
    }

    const setDate = (data, field) => {
      formData[field] = dayjs(data.target.value).toDate()
      store.dispatch('setSMHField', {field: field, value: dayjs(data.target.value).toDate()})
    }

    const setToToday = (data, field) => {
      if (dayjs(data).isValid()) {
        formData[field] = dayjs(data).toDate()
        store.dispatch('setSMHField', {field: field, value: dayjs(data).toDate()})
      }
    }

    const updateSMH = (value, field) => {
        console.log('value is ', value);
        formData[field] = value
        store.dispatch('setSMHField', {field: field, value: value})
    }

    const updateDropdown = (value, field) => {
        console.log('value is ', value);
        formData[field] = value
        store.dispatch('setSMHField', {field: field, value: value})
    }

    const currencyOptions = computed(() => {
      const currencyOpts = store.state.selections.currencyOptions;
      if (currencyOpts && currencyOpts.error) {
        store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Currency Select',
          detail: currencyOpts.message
        })
        store.dispatch('getCurrencyOptions')
        return [
          {
            "currencyCode": "GBP",
            "currencySymbol": "\u00a3",
            "numericCode": "826",
            "conversionrate": "1.00000000000000",
            "date": "2021-07-21"
          }
        ];
      } else {
        return currencyOpts;
      }
    })

    const updateCurrencySelected = (data) => {
      formData.currency = data.value
      store.dispatch('setSMHField', {field: 'currency', value: data.value})
    }

    const toggleSelect = () => {
      useReminder.value = !useReminder.value
    }

    // @TODO: shares logic with StepThree - extract to common function
    const matchLabelFromField = (field) => {
      const staticArray = [
        {value: 'planName', label: 'Asset Name'},
      ]

      const result =  staticArray.find(entry => entry.value === field)

      const firstLetter = field.charAt(0).toUpperCase()
      const capitalizedWord = firstLetter + field.slice(1)

      return result ? result.label : capitalizedWord.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    }

    return {
      formData,
      setToToday,
      setDate,
      updateDate,
      today,
      useReminder,
      updateSMH,
      currencyOptions,
      updateCurrencySelected,
      toggleSelect,
      selected: computed(() => store.getters.getSelfManagedDetails),
      matchLabelFromField,
      updateDropdown
    }
  }
}
</script>
<style scoped lang="scss">
.filter-group {
  display: flex;
  width: 100%;
  background: var(--clarity-snow-grey);
  border-radius: 27px;
  padding: 20px 0 20px 20px;
}

.title-dropdown {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 1023px) {
  .title-dropdown {
    width: 100%;
  }
}

.options-wrapper {
  flex-wrap: wrap;
}
</style>
