<template>
  <DataTable class="rd-table" :value="holdings">
    <Column header="Holding Name" field="name">
      <template #body="slotProps">
        <h3 class="light-blue">{{ typeof slotProps.data.fund === 'object' ? slotProps.data.fund.label : slotProps.data.fund ?? slotProps.data.name}} <span v-if="slotProps.data.usingClarityData" class="material-icons blue" style="vertical-align: text-bottom;" v-tooltip.top="'clarity Pre-Filled Data'" >lock</span></h3>
      </template>
    </Column>
    <Column header="ISIN/Stock Code" v-if="mode != 'liability'" class="hide-column" style="width: 130px">
      <template #body="slotProps">
        {{ slotProps.data.fund && slotProps.data.fund.id ? slotProps.data.fund.id : slotProps.data.fundCode ?? slotProps.data.code }}
      </template>
    </Column>
    <Column header="Units" field="units" class="hide-column" style="width: 130px"></Column>
    <Column header="Unit Price" class="hide-column" style="width: 130px">
      <template #body="slotProps">
        {{ $currency(slotProps.data.currency, convertPrice(slotProps.data), 2) }}
      </template>
    </Column>
    <Column header="Date" field="valuationDate" style="width: 150px">
      <template #body="slotProps">
        {{ $date(slotProps.data.valuationDate, 'DD/MM/YYYY') }}
      </template>
    </Column>
    <Column header="Current Value" field="value" style="width: 150px">
      <template #body="slotProps">
        {{ $currency(slotProps.data.currency, convertValue(slotProps.data), 2) }}
      </template>
    </Column>
    <Column header="Actions" v-if="canEdit" style="width: 110px">
      <template #body="slotProps">
      <div class="flex">
        <div><span class="material-icons grey ml-3 cursor-pointer" v-tooltip.top="'Edit Holding'" @click="editEntry(slotProps.index)">edit</span></div>
        <div><span class="material-icons grey ml-3 cursor-pointer" v-tooltip.top="'Delete Holding'" @click="removeRow(slotProps.index)">delete_forever</span></div>
        </div>
      </template>
    </Column>
    <template #footer>
      <div class="flex justify-content-center" v-if="canEdit">
        <Button class="clarity-btn clarity-gradient-orange" @click="addRow"><span class="material-icons-round white mr-3">add</span> Add
          a New Holding
        </Button>
      </div>
    </template>
    <template #empty>
      <h4 class="flex justify-content-center">No holdings currently available.</h4>
    </template>
  </DataTable>
</template>

<script>
import {computed, ref, inject} from "vue";
import {useStore} from "vuex";

export default {
  name: "SMHHoldingsTable",
  props: {
    editable: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const canEdit = ref(props.editable ?? true)
    const mode = computed(() => store.state.selfManaged.mode)

    const addRow = () => {
      bus.emit('resetSMHForm')
      store.dispatch('setActiveModal', {modal: 'SelfManagedHoldingRecordModal',  mode: 'create'});
    }

    const editEntry = (idx) => {
      store.dispatch('setEditSMHoldingRecord', idx)
      store.dispatch('setActiveModal', {modal: 'SelfManagedHoldingRecordModal', mode: 'edit', origin: 'HoldingTable'});
    }

    const removeRow = (row) => {
      store.dispatch('removeSMHoldingRecord', row)

    }

    const convertPrice = (data) => {
        let thePrice = data.unitPrice;
        if(data.currency != 'GBP') {
            const rate = conversionRate(data.currency);
            thePrice = thePrice / rate;
        }
        return thePrice
    }

    const convertValue = (data) => {
        let thePrice = data.unitPrice;
        if(data.currency != 'GBP'){
            const rate = conversionRate(data.currency);
            thePrice = thePrice / rate;
        }
        return thePrice * data.units
    }

    const conversionRate = (currency) => {
        // if display is not in GBP we need to convert the currency.
        const currencyOptions = computed(() => store.state.selections.currencyOptions);
        if(currencyOptions.value) {
            const conversionCurrency = currencyOptions.value.find(obj => {
                return obj.currencyCode === currency
            });
            if (conversionCurrency) {
                return conversionCurrency.conversionrate;
            }
        }
        return 1;
    }

    return {
      addRow,
      mode,
      canEdit,
      holdings: computed(() => store.getters.getHoldings),
      editEntry,
      removeRow,
      convertPrice,
      convertValue
    }
  }
}
</script>

<style scoped>
.clarity-btn {
  padding: 15px 45px;
  justify-content: center;
}

</style>
