<template>
  <div class="input-class">
    <label for="">{{ name }}:</label>
    <div class="flex full-width">
      <Dropdown class="mr-3" v-model="dropValue" style="width: 100%;"
                :options="dropdownOptions.options"
                :optionLabel="dropdownOptions.optionLabel" :optionValue="dropdownOptions.optionValue"
                v-on:change="emitChange" />
    </div>
  </div>
</template>

<script>
import {ref} from "vue"

export default {
  name: "OptionsSelect",
  props: {
    name: {
      type: String,
      required: true
    },
    dropdownValue: {
      type: String,
      required: false
    },
    dropdownOptions: {
      type: Object,
      required: false,
    }
  },
  emits: ['updateDropdown'],
  setup(props, context) {
    const dropValue = ref(props.dropdownValue)

    const emitChange = () => {
        console.log('change detected!');
        context.emit('updateDropdown', dropValue.value)
    }

    return {
      dropValue,
      emitChange
    }
  }
}
</script>

<style scoped>
.input-class {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.input-class label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}
</style>
