<template>
  <div class="input-class">
    <label for="">{{ label }}:</label>
      <InputText class="full-width" v-model="formValue" :placeholder="placeholder ?? 'Please Specify...'"/>
  </div>
</template>

<script>
import {ref, watch} from "vue"

export default {
  name: "OptionsTextInput",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  emits: ['updateField'],
  setup(props, context) {
    const formValue = ref(props.value ?? '')

    watch(formValue, (value) => {
        context.emit('updateField', value)
    })

    return {
      formValue,
    }
  }
}
</script>

<style scoped>
.input-class {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.input-class label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}
</style>
