<template>
  <div class="content-full">
    <div>
      <h3>Step 2: Entering Details</h3>
    </div>
    <div class="full-width">
      <div class="title-dropdown">
        <label for="" v-if="mode === 'liability'">Liability Name:</label>
        <label for="" v-else>Asset Name:</label>
        <InputText v-model="holdingName" class="full-width" placeholder="Please Specify..." required
                   @change="updateHoldingName"></InputText>
      </div>
    </div>
    <div class="flex flex-wrap my-4">
      <div class="half title-dropdown">
          <label for=""><span v-if="mode === 'liability'">Liability</span><span v-else>Investment</span> Type:</label>
        <Dropdown class="full-width" v-model="product" :options="investmentOptions" optionLabel="label" @change="updateSelectedProduct"></Dropdown>
      </div>
      <div class="half title-dropdown">
        <label for="">Ownership:</label>
        <Dropdown class="full-width" :options="peopleOptions" v-model="ownership" @change="setOwnershipFromDropdown" ref="peopleDropdown">
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ slotProps.option.name }} <span class="grey">(100%)</span></div>
            </div>
          </template>
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 1" class="flex align-items-center">
              <div>
                <span v-for="owner in slotProps.value" :key="owner.id">{{ owner.name }} <span
                    class="grey">({{ owner.percentage.toFixed(2) }}%)</span> &nbsp;</span>
              </div>
            </div>
            <div v-else-if="slotProps.value && slotProps.value.length">
              <div>{{ slotProps.value[0].name }} <span class="grey">(100%)</span></div>
            </div>
            <div v-else>{{ slotProps.placeholder }}</div>
          </template>
          <template #footer>
            <div class="flex align-items-center dropdown-footer" @click="addCustomSplit">
              <span class="material-icons-round mr-2">edit</span>
              <div>Customise Percentages</div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>

    <div class="my-4" v-if="!holdings.length">
      <h3 class="mb-3">valuation details</h3>
      <div class="blank-button-div full-width">
        <p class="body">Please add one or more holdings to this <span v-if="mode === 'liability'">liability</span><span v-else>asset</span>.</p>
        <Button class="clarity-btn clarity-gradient-orange" @click="addNewHolding"><span
            class="material-icons-round white mr-3">add</span> Add
          a New Holding
        </Button>
      </div>
    </div>

    <div class="my-4" v-if="holdings.length">
      <SMHHoldingsTable :editable="true"/>
    </div>

    <div class="flex justify-content-between my-4">
      <h3 class="mb-3">advanced options</h3>
      <div class="flex">
        <div :class="[ isSelected ? '' : 'selected-option']">Disable Advanced Options</div>
        <InputSwitch v-model="showAdvancedOptions" @click="toggleAdvancedOptions" class="mx-3"/>
        <div :class="[ isSelected ? 'selected-option': '']">Enable Advanced Options</div>
      </div>
    </div>
    <div v-if="showAdvancedOptions">
      <AdvancedOptionsGroup :product="product" :isEditing="isEditing"/>
    </div>


    <div class="flex justify-content-between mt-3" style="width: 100%">
      <Button class="clarity-btn clarity-gradient-grey" @click="dismiss"><span class="material-icons-round mr-3">chevron_left</span>Go
        Back
      </Button>
      <submit-button @submitted="confirmSelection" :loading="loadingState" button-text="Confirm and Continue" loading-text="Saving..." style="padding: 5px;" :disabled="checkDisabled()" :chevron=true></submit-button>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, onMounted, ref, watch, inject} from "vue";
import api from '@/core/services/api';
import { toDrupalRequest } from '@/core/helpers';
import SMHHoldingsTable from "@/components/selfManaged/SMHHoldingsTable";
import AdvancedOptionsGroup from "@/components/selfManaged/advancedOptions/AdvancedOptionsGroup";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";
import RD3Error from "../../core/classes/RD3Error";


export default {
  name: "StepTwoDetails",
  components: {
    AdvancedOptionsGroup, SMHHoldingsTable, SubmitButton
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const bus = inject('bus')
    const mode = computed(() => store.state.selfManaged.mode)
    const investmentOptions = ref([])
    const selectedType = ref()
    const product = ref()
    const productType = ref()
    const ownership = ref()
    const showAdvancedOptions = ref(false)
    const holdingName = ref(null)
    const finalPayload = computed(() => store.getters.selfManagedFinalPayload)
    const peopleDropdown = ref(null)
    // may need this to force close the dropdown
    // const isCustomDialogOpen = computed(() => store.getters.currentActiveModal)
    const loadingState = computed(() => store.getters.isLoading('SaveSelfManaged'))

    const assetId = ref(false)
    const liabilityId = ref(false)
    onMounted(() => {
      const data = store.getters.getSelfManagedDetails
      if (data) {
          assetId.value = data.assetId;
          liabilityId.value = data.liabilityId;

          let owners = []
          if (data.ownership && data.ownership.length) {
              data.ownership.forEach(person => {
                  let o = store.getters.personFromUuid(person.owner)
                  owners.push({...o, percentage: person.ownership})
              })
          }
          data.planName ? holdingName.value = data.planName : ''
          if (data.ownership && data.ownership.length) {
              ownership.value = owners
              store.dispatch('setSelfManagedSplit', owners)
          }
      }
    })

    const holdings = computed(() => store.getters.getHoldings)

    const toggleAdvancedOptions = () => {
      showAdvancedOptions.value = !showAdvancedOptions.value
    }

    const isSelected = computed(() => {
      return showAdvancedOptions.value
    })

    const confirmSelection = async () => {
      store.dispatch('setLoading', {id: 'SaveSelfManaged', state: true})

      await store.dispatch('addSMHAdvancedOptions', {})

      // now go ahead and try to commit the data we have collected.
      if(process.env.VUE_APP_DELAY_BACKEND_API) {
          await new Promise(r => setTimeout(r, 2000));
      }

      if(mode.value === 'liability'){
          const endpoint = '/selfmanaged/liabilities';
          const editCurrentHolding = store.getters.isEditingExistingValuationHolding
          const editCurrentHoldingIndex = store.getters.isEditingExistingValuationHoldingIndex

          let response;
          if (editCurrentHolding) {
              // ensure the asset id is set.
              finalPayload.value.liabilityId = liabilityId.value;
              response = await api.patch(
                  `${toDrupalRequest(endpoint)}`,
                  JSON.stringify(finalPayload.value),
                  {
                      headers: {
                          'Content-Type': 'application/json'
                      }
                  })

          } else {
              response = await api.post(
                  `${toDrupalRequest(endpoint)}`,
                  JSON.stringify(finalPayload.value),
                  {
                      headers: {
                          'Content-Type': 'application/json'
                      }
                  })
          }

          if (response === undefined || response.error) {
              await store.dispatch('addNotification', {
                  severity: 'error',
                  summary: 'Add Self Managed Liability',
                  detail: `Unable to create/update self managed liability record. If the problem persists, please contact enquiries@clarityglobal.com`
              })
              store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})
          }else{
              if (editCurrentHolding) {
                  updateSelfManagedHolding(editCurrentHoldingIndex)
              } else if (response.success) {
                  addSelfManagedHolding(response)
              } else {

                  // some other error ocurred. Record this.
                  await store.dispatch('addNotification', {
                      severity: 'error',
                      summary: 'Add Self Managed Liability',
                      detail: `Unable to create/update self managed liability record. If the problem persists, please contact enquiries@clarityglobal.com`
                  })
                  store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})
              }
          }
      }else {
          const endpoint = '/selfmanaged/assets';
          const editCurrentHolding = store.getters.isEditingExistingValuationHolding
          const editCurrentHoldingIndex = store.getters.isEditingExistingValuationHoldingIndex

          let response = false;
          if (editCurrentHolding) {
              // ensure the asset id is set.
              finalPayload.value.assetId = assetId.value;
              response = await api.patch(
                  `${toDrupalRequest(endpoint)}`,
                  JSON.stringify(finalPayload.value),
                  {
                      headers: {
                          'Content-Type': 'application/json'
                      }
                  })

          } else {
              response = await api.post(
                  `${toDrupalRequest(endpoint)}`,
                  JSON.stringify(finalPayload.value),
                  {
                      headers: {
                          'Content-Type': 'application/json'
                      }
                  })
          }

          if (response === undefined || response.error) {
              await store.dispatch('addNotification', {
                  severity: 'error',
                  summary: 'Add Self Managed Holding',
                  detail: `Unable to create/update self managed holding record. If the problem persists, please contact enquiries@clarityglobal.com`
              })
              store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})
              throw new RD3Error('Error with self managed holdings request: ' + JSON.stringify(finalPayload.value), 'RD3 Failed Request')
          }else {
              if (editCurrentHolding) {
                  updateSelfManagedHolding(editCurrentHoldingIndex)
              } else if (response.success) {
                  addSelfManagedHolding(response)
              } else {
                  // some other error ocurred. Record this.
                  await store.dispatch('addNotification', {
                      severity: 'error',
                      summary: 'Add Self Managed Holding',
                      detail: `Unable to create/update self managed holding record. If the problem persists, please contact enquiries@clarityglobal.com`
                  })
                  store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})
              }
          }
      }

    }

    const addSelfManagedHolding = async (response) => {

        if(mode.value === 'liability') {
            //we are going to add it!
            store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Self Managed Liability Added',
                detail: `${holdingName.value}`,
                life: 4000
            })
            await store.dispatch('addSMHAdvancedOptions')

            const finalPayload = store.getters.selfManagedFinalPayload
            await store.dispatch('addSelfManagedLiability', {finalPayload: finalPayload, liabilityId: response.liabilityId})
            await store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 3, completed: 2})
            await store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})

            // lastly we push!
            router.push('/investments/selfmanaged');
        }else{
            //we are going to add it!
            store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Self Managed Holding Added',
                detail: `${holdingName.value}`,
                life: 4000
            })
            await store.dispatch('addSMHAdvancedOptions')

            const finalPayload = store.getters.selfManagedFinalPayload
            await store.dispatch('addSelfManagedHolding', {finalPayload: finalPayload, assetId: response.assetId})
            await store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 3, completed: 2})
            await store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})

            // lastly we push!
            router.push('/investments/selfmanaged');
        }

    }

    const updateSelfManagedHolding = async (index) => {

        if(mode.value === 'liability') {
            //we are going to add it!
            store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Self Managed Liability Added',
                detail: `${holdingName.value}`,
                life: 4000
            })
            await store.dispatch('addSMHAdvancedOptions')

            const finalPayload = store.getters.selfManagedFinalPayload
            finalPayload.liabilityId = liabilityId.value;

            // update self managed with index.
            await store.dispatch('saveSelfManagedLiability', {finalPayload: finalPayload, index: index})
        }else {
            //we are going to add it!
            store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Self Managed Holding Added',
                detail: `${holdingName.value}`,
                life: 4000
            })
            await store.dispatch('addSMHAdvancedOptions')

            const finalPayload = store.getters.selfManagedFinalPayload
            finalPayload.assetId = assetId.value;

            // update self managed with index.
            await store.dispatch('saveSelfManagedHolding', {finalPayload: finalPayload, index: index})
        }

        await store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 3, completed: 2})
        await store.dispatch('setLoading', {id: 'SaveSelfManaged', state: false})
        // lastly we push!
        router.push('/investments/selfmanaged');

    }

    // for display once custom split set in modal
    const getSplit = computed(() => store.getters.getPercentageSplit)

    watch(getSplit, newSplit => {
      ownership.value = newSplit
      store.dispatch('setSelfManagedSplit', newSplit)
    })

    const selectedProduct = computed(() => store.getters.getSelectedProductObject)

    const type = computed(() => store.getters.selectedNewSelfManagedType)

    const investmentProducts = computed(() => store.getters.productsForType(type.value))

    onMounted(() => {
      selectedType.value = type.value
      investmentOptions.value = [...investmentProducts.value]
      product.value = selectedProduct.value
      productType.value = store.getters.getParentOfProduct(selectedProduct.value.id)
      getPeopleOptions()
    })

    const getPeopleOptions = () => {
      store.dispatch('getPeopleOptions')
    }

    const peopleOptions = computed(() => {
      const options = store.state.selections.peopleOptions;
      if (options && options.error) {
        store.dispatch('addNotification', {severity: 'error', summary: 'People Select', detail: options.message})
        return [];
      } else {
        if (options && options.length) {
          setOwnership(options[0])
        }
        return options;
      }
    })

    const setOwnershipFromDropdown = (owner) => {
      setOwnership(owner.value)
    }

    const setOwnership = (owner) => {
      ownership.value = owner
      store.dispatch('setSelfManagedSplit', [{...owner, percentage: 100}])
    }

    const addCustomSplit = () => {
      if(peopleDropdown.value){
          peopleDropdown.value.hide()
      }
      store.dispatch('setActiveModal', {modal: 'newSelfManagedSplitModal'});
    }

    const getDisplaySplit = (data) => {
      let rtnString = ''
      data.forEach(owner => {
        rtnString += `${owner.name}`
      })

      return rtnString
    }

    const updateHoldingName = (name) => {
      store.dispatch('setSMHField', {field: 'planName', value: name.target.value})
    }

    const addNewHolding = () => {
      bus.emit('resetSMHForm')
      store.dispatch('setActiveModal', {modal: 'SelfManagedHoldingRecordModal'});
    }

    const checkDisabled = () => {
      return holdingName.value === null || holdings.value.length < 1
    }

    const resetForm = () => {
      selectedType.value = null
      product.value = null
      productType.value = null
      ownership.value = null
      showAdvancedOptions.value = false
      holdingName.value = null
    }

    const dismiss = () => {
      resetForm()
      store.dispatch('resetSMHStep', 2)
      bus.emit('resetSMHForm')
      router.back()
    }

    const updateSelectedProduct = (product) => {
        store.dispatch('updateSelectedProduct', product.value.id)
    }

    return {
      mode,
      confirmSelection,
      investmentOptions,
      selectedType,
      product,
      productType,
      peopleOptions,
      ownership,
      addCustomSplit,
      getDisplaySplit,
      setOwnershipFromDropdown,
      showAdvancedOptions,
      toggleAdvancedOptions,
      isSelected,
      updateHoldingName,
      holdingName,
      addNewHolding,
      holdings,
      checkDisabled,
      dismiss,
      isEditing: computed(() => store.getters.isEditingExistingValuationHolding),
      loadingState,
      assetId,
      liabilityId,
      peopleDropdown,
      updateSelectedProduct
    }
  }
}
</script>

<style scoped lang="scss">
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.half {
  width: 50%;
}

.blank-button-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--clarity-snow-grey);
  border-radius: 27px;
  padding: 60px 20px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.clarity-btn {
  padding: 15px 45px;
  justify-content: center;
}

.title-dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.title-dropdown label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}

.title-dropdown:nth-of-type(2) {
  padding-left: 20px;
}

:deep(.p-inputtext) {
  color: var(--clarity-blue);
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
}

.dropdown-footer {
  border-top: 2px solid var(--clarity-light-grey);
  padding: 15px;
  display: flex;
  align-content: center;

  &:hover {
    cursor: pointer;
    background-color: var(--clarity-snow-grey);
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
  }
}

.selected-option {
  font-weight: bold;
}

@media only screen and (max-width: 1023px) {
  .half {
    width: 100%;
  }
  .title-dropdown:nth-of-type(2) {
    padding-left: 0px;
  }
}
</style>
