<template>
  <div class="input-class">
    <label for="">{{ name }}:</label>
    <div class="flex full-width">
      <InputNumber v-if="mode === 'currency'" class="full-width" v-model="formValue" @focus="moveCursorToStart" mode="currency" :currency="dropValue" />
      <InputNumber v-else class="full-width" v-model="formValue" @focus="moveCursorToStart" />
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue"
import {useStore} from "vuex";
import InputNumber from "primevue/inputnumber";

export default {
  name: "OptionsNumber",
  components: { InputNumber },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      required: false
    },
    dropdownOptions: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: false
    }
  },
  emits: ['updateField'],
  setup(props, context) {
    const store = useStore()
    const formValue = ref(props.value ? parseFloat(props.value) : 0.00)
    const initialOption = computed(() => store.getters.currencySelected );
    const dropValue = ref(initialOption.value)

    const moveCursorToStart = (event) => {
        const inputElement = event.target;
        inputElement.setSelectionRange(0, 0);
    }

    watch(formValue, (value) => {
        context.emit('updateField', value)
    })

    return {
      formValue,
      dropValue,
      moveCursorToStart
    }
  }
}
</script>

<style scoped>
.input-class {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.input-class label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}
</style>
