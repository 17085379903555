<template>
  <div class="input-class full-width">
    <label for="">{{ label }}:</label>
    <div class="flex full-width">
      <span class="wrapper">
          <Calendar id="basic" v-model="formValue" dateFormat="dd/mm/yy" autocomplete="off"
                    @date-select="$emit('updateDate', $event)" @change="$emit('setDate', $event)"
                    panel-class="calendar-valuationdate"
                    ref="datepicker">
            <template #footer>
              <div class="flex flex-row-reverse flex-wrap">
                <div class="flex align-items-center justify-content-center m-2">
                  <Button @click="setToToday" class="clarity-btn clarity-gradient-orange">Set As Today</Button>
                </div>
              </div>
            </template>
          </Calendar>
          <span class="material-icons-round calendar-icon grey cursor-auto">today</span>
        </span>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {ref} from "vue";

export default {
  name: "OptionsDate",
  props: {
    value: {
      required: false
    },
    label: {
      type: String,
      required: false
    },
    selectValue: {
      type: Boolean,
      required: false,
    },
    selectLabel: {
      type: String,
      required: false
    }
  },
  emits: ['toggleSelect', 'setToToday', 'setDate', 'updateDate'],
  setup(props, context) {
    const today = dayjs().toDate();
    const formValue = ref(dayjs(props.value).toDate())
    const selectState = ref(props.selectValue)

    const setToToday = () => {
      formValue.value = today;
      context.emit('setToToday', today)
    }

    return {
      formValue,
      today,
      selectState,
      setToToday,
    }
  }
}
</script>

<style scoped lang="scss">
.input-class {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.input-class label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}

.wrapper {
  position: relative;
  width: 100%;
}

.wrapper:deep(.p-inputwrapper) {
  width: 100%;
}

.calendar-icon {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
}
</style>
