<template>
  <div class="flex full-width">
    <div class="flex flex-column title-dropdown notes">
      <label for="">{{ label }}:</label>
      <Textarea v-model="notes" class="full-width"
                :placeholder="placeholder"
                rows="5" cols="240" auto-resize @change="$emit('updateNotes', $event)" />
    </div>
  </div>
</template>

<script>
import {ref} from "vue"

export default {
  name: "OptionsNotes",
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  emits: ['updateNotes'],
  setup(props) {
    const notes = ref(props.value)

    return {
      notes
    }

  }
}
</script>

<style scoped lang="scss">
.title-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 33%;
  margin-bottom: 10px;
}

.title-dropdown label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}
.title-dropdown:nth-of-type(1) {
  padding-right: 30px;

  &.notes {
    padding-right: 10px;
  }
}
</style>